import React from 'react';
import { WindowLocation } from '@reach/router';
import { LocationState } from 'history';
import { Nav, Navbar } from 'react-bootstrap';

type Props = {
  title: string,
  location: WindowLocation<LocationState>
};

const MENU_ITEMS = [
  { name: 'Ecological Disasters', link: '/' },
  { name: 'Conservation', link: '/conservation' },
  { name: 'Media', link: '/media' },
  { name: 'Our Vision', link: '/our-vision' },
  { name: 'Contact', link: '/contact' }
];

export const Header: React.FC<Props> = (props) => {
  return (
    <Navbar variant="dark" bg="dark" expand="lg">
      <Navbar.Brand href="/">{props.title}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className={'justify-content-end'}>
        <Nav>
          {MENU_ITEMS.map(({ name, link }) => {
            return <Nav.Link href={link} key={link}>{name}</Nav.Link>
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};