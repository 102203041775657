import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AdSense from 'react-adsense';

type Props = {

};

export const Footer: React.FC<Props> = (props) => {
  return (
    <footer className={'bg-dark'}>
      <Container>
        <Row>
          <Col>
            <span>TODAY’S ACTIONS. FUTURE’ S CONSEQUENCE.</span>
            <br />
            <span>Copyright 2020-2021</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};