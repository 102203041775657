import React from 'react';
import { Header } from './header';
import { Footer } from './footer';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  return (
    <div className="page-container">
      <div data-is-root-path={isRootPath}>
        <Header title={title} location={location} />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
